import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from 'layouts/Default'
import Container from 'components/Container'
import HeaderSection from 'components/Section/headerSection'
import PageSection from 'components/Section/pageSection'
import styled from 'styled-components'

const Body = styled.div`
  p:first-child {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 70px;
  }

  h1 {
    font-size: 24px;
    line-height: 40px;
  }

  p:not(:first-child) {
    font-size: 20px;
    line-height: 28px;
  }
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Impressum = ({ data, ...restProps }) => {
  const title = data.kenticoCloudItemGeneralArticle.elements.title.value
  const body =
    data.kenticoCloudItemGeneralArticle.elements.body_text.resolvedHtml
  return (
    <Layout {...restProps}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}/impressum`}
        />
      </Helmet>
      <PageContainer>
        <HeaderSection header headline={title} richText />
        <PageSection>
          <Container>
            <Body
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          </Container>
        </PageSection>
      </PageContainer>
    </Layout>
  )
}

export const query = graphql`
  {
    kenticoCloudItemGeneralArticle(system: { codename: { eq: "impressum" } }) {
      id
      elements {
        title {
          value
        }
        slug {
          value
        }
        body_text {
          resolvedHtml
        }
      }
    }
  }
`

Impressum.propTypes = {
  data: PropTypes.object,
}

export default Impressum
